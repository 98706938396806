import { InjectedConnector } from "wagmi/connectors/injected";

export class Coins98WalletConnector extends InjectedConnector {
  constructor(config) {
    super({
      ...config,
      options: {
        name: "Coins98 Wallet",
        shimDisconnect: true,
        ...config?.options,
      },
    });
    this.id = "coin98";
    this.ready =
      typeof window != "undefined" &&
      !!this.findProvider(window.coin98?.provider);
  }

  async getProvider() {
    if (typeof window !== "undefined") {
      this.provider = this.findProvider(window.coin98?.provider);
    }
    return this.provider;
  }

  getReady(coins98) {
    if (!coins98) return;

    return coins98;
  }

  findProvider(coins98) {
    if (coins98?.providers) return coins98.providers.find(this.getReady);
    return this.getReady(coins98);
  }
}

export class SafePalWalletConnector extends InjectedConnector {
  constructor(config) {
    super({
      ...config,
      options: {
        name: "SafePal Wallet",
        shimDisconnect: true,
        ...config?.options,
      },
    });
    this.id = "safepal";
    this.ready =
      typeof window != "undefined" &&
      !!this.findProvider(window.safepalProvider);
  }

  async getProvider() {
    if (typeof window !== "undefined") {
      this.provider = this.findProvider(window.safepalProvider);
    }
    return this.provider;
  }

  getReady(safepal) {
    if (!safepal) return;

    return safepal;
  }

  findProvider(safepal) {
    if (safepal?.providers) return safepal.providers.find(this.getReady);
    return this.getReady(safepal);
  }
}

export class MathWalletConnector extends InjectedConnector {
  constructor(config) {
    super({
      ...config,
      options: {
        name: "Math Wallet",
        shimDisconnect: true,
        ...config?.options,
      },
    });
    this.id = "mathwallet";
    this.ready =
      typeof window != "undefined" &&
      window.ethereum &&
      window.ethereum.isMathWallet &&
      !!this.findProvider(window.ethereum);
  }

  async getProvider() {
    if (typeof window !== "undefined") {
      this.provider =
        window.ethereum && window.ethereum.isMathWallet
          ? this.findProvider(window.ethereum)
          : undefined;
    }
    return this.provider;
  }

  getReady(mathwallet) {
    if (!mathwallet) return;

    return mathwallet;
  }

  findProvider(mathwallet) {
    if (mathwallet?.providers) return mathwallet.providers.find(this.getReady);
    return this.getReady(mathwallet);
  }
}

export class TrustWalletConnector extends InjectedConnector {
  constructor(config) {
    super({
      ...config,
      options: {
        name: "Trust Wallet",
        shimDisconnect: true,
        ...config?.options,
      },
    });
    this.id = "trustwallet";
    this.ready =
      typeof window != "undefined" && !!this.findProvider(window.trustwallet);
  }

  async getProvider() {
    if (typeof window !== "undefined") {
      this.provider = this.findProvider(window.trustwallet);
    }
    return this.provider;
  }

  getReady(trustwallet) {
    if (!trustwallet) return;

    return trustwallet;
  }

  findProvider(trustwallet) {
    if (trustwallet?.providers)
      return trustwallet.providers.find(this.getReady);
    return this.getReady(trustwallet);
  }
}

export class BitKeepConnector extends InjectedConnector {
  constructor(config) {
    super({
      ...config,
      options: {
        name: "BitKeep Wallet",
        shimDisconnect: true,
        ...config?.options,
      },
    });
    this.id = "bitkeep";
    this.ready =
      typeof window != "undefined" &&
      !!this.findProvider(window.bitkeep?.ethereum);
  }

  async getProvider() {
    if (typeof window !== "undefined") {
      this.provider = this.findProvider(window.window.bitkeep?.ethereum);
    }
    return this.provider;
  }

  getReady(bitkeep) {
    if (!bitkeep) return;

    return bitkeep;
  }

  findProvider(bitkeep) {
    if (bitkeep?.providers) return bitkeep.providers.find(this.getReady);
    return this.getReady(bitkeep);
  }
}

export class OkexConnector extends InjectedConnector {
  constructor(config) {
    super({
      ...config,
      options: {
        name: "OKX Wallet",
        shimDisconnect: true,
        ...config?.options,
      },
    });
    this.id = "okex";
    this.ready =
      typeof window != "undefined" && !!this.findProvider(window.okexchain);
  }

  async getProvider() {
    if (typeof window !== "undefined") {
      this.provider = this.findProvider(window.okexchain);
    }
    return this.provider;
  }

  getReady(okex) {
    if (!okex) return;

    return okex;
  }

  findProvider(okex) {
    if (okex?.providers) return okex.providers.find(this.getReady);
    return this.getReady(okex);
  }
}

export class KuCoinWalletConnector extends InjectedConnector {
  constructor(config) {
    super({
      ...config,
      options: {
        name: "KuCoin Wallet",
        shimDisconnect: true,
        ...config?.options,
      },
    });
    this.id = "kucoin";
    this.ready =
      typeof window != "undefined" &&
      window.ethereum &&
      window.ethereum.isKuCoinWallet &&
      !!this.findProvider(window.ethereum);
  }

  async getProvider() {
    if (typeof window !== "undefined") {
      this.provider =
        typeof window != "undefined" &&
        window.ethereum &&
        window.ethereum.isKuCoinWallet
          ? this.findProvider(window.ethereum)
          : undefined;
    }
    return this.provider;
  }

  getReady(wallet) {
    if (!wallet) return;

    return wallet;
  }

  findProvider(wallet) {
    if (wallet?.providers) return wallet.providers.find(this.getReady);
    return this.getReady(wallet);
  }
}
