import {
  Button,
  Flex,
  Heading,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  Text,
} from "@chakra-ui/react";
import React from "react";

const TransactionForm = ({ inputAmount, onWithdraw, source, type }) => {
  return (
    <>
      <ModalHeader textAlign="center">
        Claim SD Tokens
        <Text fontSize="small" mt="16px" fontWeight="400">
          {` You are claiming SD tokens from ${source} ${type}`}
        </Text>
      </ModalHeader>
      <ModalCloseButton
        color="#8C8C8C"
        size="lg"
        _focus={{
          boxShadow: "0 0 0 0 ",
        }}
      />
      <ModalBody pb="40px" pt="24px">
        <Flex justifyContent={"center"} paddingBottom={"40px"}>
          <Heading>SD {inputAmount / 10 ** 18}</Heading>
        </Flex>

        <Button
          variant="outline"
          _hover={{ bg: "white", color: "black" }}
          _active={{ bg: "white", color: "black" }}
          fontSize="lg"
          height="48px"
          width="100%"
          py="15px"
          borderRadius="0px"
          _focus={{
            boxShadow:
              "0 0 0 0 rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)",
          }}
          onClick={onWithdraw}
        >
          Withdraw to Wallet
        </Button>
      </ModalBody>
    </>
  );
};

export default TransactionForm;
