import { Box, Button, ModalBody, Text } from "@chakra-ui/react";
import React from "react";

const FailIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="40" cy="40" r="38.5" stroke="#F9F9F9" strokeWidth="3" />
    <path
      d="M41.8225 51.1775C42.3634 51.7183 42.6338 52.3793 42.6338 53.1606C42.6338 53.9418 42.3634 54.6028 41.8225 55.1437C41.2817 55.7146 40.6206 56 39.8394 56C39.0582 56 38.3972 55.7146 37.8563 55.1437C37.2854 54.6028 37 53.9418 37 53.1606C37 52.3793 37.2854 51.7183 37.8563 51.1775C38.3972 50.6366 39.0582 50.3662 39.8394 50.3662C40.6206 50.3662 41.2817 50.6366 41.8225 51.1775ZM42.1831 24L42.1831 46.9859L37.4507 46.9859L37.4507 24L42.1831 24Z"
      fill="#F9F9F9"
    />
  </svg>
);

const Fail = ({ reason, onClose }) => {
  if (!reason || reason.length > 30) {
    reason = "unknown error. Please try again later.";
  }
  return (
    <>
      <ModalBody pb="40px" pt="32px" minHeight="361px">
        <Box
          textAlign="center"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          position="absolute"
          left={0}
          right={0}
          top={0}
          bottom={0}
        >
          <FailIcon />
          <Text fontSize="16px" fontWeight={600} mt="32px">
            Your transaction has failed due to {reason}
          </Text>
          <Button
            mt={"30px"}
            onClick={onClose}
            variant="outline"
            _hover={{ bg: "white", color: "black" }}
            _active={{ bg: "white", color: "black" }}
            pl={12}
            pr={12}
            fontSize="14px"
            borderRadius="0px"
            _focus={{
              boxShadow:
                "0 0 0 0 rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)",
            }}
          >
            Close
          </Button>
        </Box>
      </ModalBody>
    </>
  );
};

export default Fail;
