// import { useToast } from "@chakra-ui/react";
import merkleDistributor from "contracts/MerkleDistributor.json";
import { Contract } from "ethers";
import { useState } from "react";
import { useNetwork, useSigner } from "wagmi";

const useContractData = () => {
  const [receipt, setReceipt] = useState(null);
  const [isClaimed, setIsClaimed] = useState(null);
  const [transactionHash, setTransactionHash] = useState(null);
  const [error, setError] = useState(null);

  const { data: signer } = useSigner();
  const { chain } = useNetwork();

  // const toast = useToast();

  // const [error, setError] = useState();

  const handleClaim = async ({
    contractAddress,
    index,
    account,
    amount,
    merkleProof,
  }) => {
    try {
      setError(null);
      setReceipt(null);
      setTransactionHash(null);

      const contract = new Contract(
        contractAddress,
        merkleDistributor.abi,
        signer,
      );

      const transaction = await contract.claim(
        index,
        account,
        amount,
        merkleProof,
      );

      setTransactionHash(transaction.hash);
      const receipt = await transaction.wait(1);
      setReceipt(receipt);
    } catch (e) {
      setError(e.message);
      console.log("Error occurred", e.message);
    }
  };

  const checkClaim = async ({ contractAddress, index }) => {
    const contract = new Contract(
      contractAddress,
      merkleDistributor.abi,
      signer,
    );

    const isClaimed = await contract.isClaimed(index);

    setIsClaimed(isClaimed);
  };

  return {
    handleClaim,
    checkClaim,
    receipt,
    chainId: chain?.id,
    isClaimed,
    transactionHash,
    error,
  };
};

export default useContractData;
