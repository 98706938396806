import React from "react";

const findCurrentRoute = (arr, value) =>
  arr.find((element) => {
    return React.isValidElement(element) && element.props.value === value;
  });

export const Case = ({ children }) =>
  React.isValidElement(children) ? children : null;
export const DefaultCase = ({ children }) => <Case value="">{children}</Case>;
export const Switcher = ({ value, children }) => {
  const arrayOfChildrens = React.Children.toArray(children);
  const element = findCurrentRoute(arrayOfChildrens, value);

  if (React.isValidElement(element)) {
    return element;
  }

  const defaultElement = findCurrentRoute(arrayOfChildrens);

  return React.isValidElement(defaultElement) ? defaultElement : null;
};
