import {
  Box,
  Button,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { config } from "config/config";
import { walletIconMapping, walletLabelMapping } from "constants/wallets";
import { getEllipsisTxt } from "helpers/formatters";
import React from "react";
import { useAccount, useConnect, useDisconnect } from "wagmi";

const styles = {
  account: {
    height: "42px",
    padding: "0 15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderColor: "#ffffff",
    borderWidth: "1px",
    width: "fit-content",
    color: "#ffffff",
    cursor: "pointer",
  },
  text: {
    color: "#F9F9F9",
    fontWeight: "bold",
  },
  connector: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: "auto",
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "20px 5px",
    cursor: "pointer",
  },
  icon: {
    alignSelf: "center",
    fill: "rgb(40, 13, 95)",
    flexShrink: "0",
    marginBottom: "8px",
    height: "30px",
  },
};

const WalletIcon = () => (
  <svg
    width="20"
    height="15"
    viewBox="0 0 20 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="19"
      height="13.2857"
      rx="1.5"
      stroke="#F9F9F9"
    />
    <rect
      x="4.28564"
      y="2.14294"
      width="15.7143"
      height="1"
      rx="0.5"
      fill="#F9F9F9"
    />
    <path
      d="M13 5.5C13 5.22386 13.2239 5 13.5 5H19V6H13.5C13.2239 6 13 5.77614 13 5.5Z"
      fill="#F9F9F9"
    />
  </svg>
);

const Account = ({ isMobile, balance }) => {
  const { address, isConnected } = useAccount();
  const toast = useToast();

  const { connect, connectors } = useConnect({
    onError(error) {
      let errorMsg = "";

      if (error.message.toString().startsWith("invalid address")) {
        errorMsg =
          "Looks like you are not connected to ETH chain. Please switch the network in the Wallet.";
      }

      toast({
        description: errorMsg || error.message,
        status: "error",
        duration: 5000,
        position: "bottom",
        isClosable: true,
      });
    },
    onSuccess() {
      onClose();
    },
  });
  const { disconnect } = useDisconnect();

  const { isOpen, onOpen, onClose } = useDisclosure();

  if (!isConnected || !address) {
    return (
      <>
        <Button
          leftIcon={<WalletIcon />}
          variant="outline"
          onClick={onOpen}
          color="white"
          _hover={{ bg: "#00000000" }}
          _active={{
            bg: "#000000",
            borderColor: "#bec3c9",
          }}
          borderRadius="0px"
          _focus={{
            boxShadow:
              "0 0 0 0 rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)",
          }}
        >
          Connect Wallet
        </Button>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent bg={"#191919"} color={"#ffffff"}>
            <ModalHeader>Connect Wallet</ModalHeader>
            <ModalCloseButton
              _focus={{
                boxShadow: "0 0 0 0 ",
              }}
            />
            <ModalBody py={8}>
              <SimpleGrid columns={2} gap="0.5rem">
                {connectors.map((connector) => (
                  <Flex
                    gap="1rem"
                    flexDir="column"
                    align="center"
                    py="0.5rem"
                    mx="1rem"
                    key={connector.id}
                    cursor="pointer"
                    border="1px solid transparent"
                    onClick={async () => {
                      try {
                        connect({ connector });
                      } catch (e) {
                        console.error(e);
                      }
                    }}
                    _hover={{
                      borderColor: "white",
                    }}
                    transition="all 0.2s ease-in-out"
                  >
                    <Flex overflow="hidden" borderRadius="50%">
                      <img
                        src={walletIconMapping[connector.id.toLowerCase()]}
                        alt={walletLabelMapping[connector.id.toLowerCase()]}
                        height="32px"
                        width="32px"
                      />
                    </Flex>
                    <Text fontSize={14}>
                      {walletLabelMapping[connector.id.toLowerCase()]}
                    </Text>
                  </Flex>
                ))}
              </SimpleGrid>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    );
  }

  const handleLogout = (e) => {
    e.preventDefault();
    disconnect();
    onClose();
  };

  return (
    <>
      <Box style={styles.account} onClick={onOpen}>
        {!isMobile && (
          <Box paddingRight={5}>
            <WalletIcon />
          </Box>
        )}
        <p style={{ marginRight: "5px", ...styles.text }}>
          {getEllipsisTxt(address, 6)}
        </p>
        <p style={styles.text}>| SD:{balance}</p>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW="480px" bg={"#191919"} color={"#ffffff"}>
          <ModalHeader>Account</ModalHeader>
          <ModalCloseButton
            _focus={{
              boxShadow: "0 0 0 0 ",
            }}
          />
          <ModalBody>
            <Flex
              flexDirection={"column"}
              padding={"5px"}
              alignContent="center"
            >
              <Box mb={3}>
                <Text
                  fontSize={"16px"}
                  fontWeight={"bold"}
                  letterSpacing={"tight"}
                  color="#8C8C8C"
                >
                  Wallet Address
                </Text>
                <Link
                  fontSize={"16px"}
                  fontWeight={"bold"}
                  letterSpacing={"tight"}
                  href={`${config.network.blockExplorerUrl}address/${address}`}
                  _focus={{
                    boxShadow: "0 0 0 0 ",
                  }}
                  isExternal
                >
                  {address}
                </Link>
              </Box>

              <Box mt={3}>
                <Text
                  fontSize={"16px"}
                  fontWeight={"bold"}
                  letterSpacing={"tight"}
                  color="#8C8C8C"
                >
                  SD Token Balance
                </Text>
                <Text
                  fontSize={"16px"}
                  fontWeight={"bold"}
                  letterSpacing={"tight"}
                >
                  {balance}
                </Text>
              </Box>

              <Button
                variant="outline"
                _hover={{ bg: "white", color: "black" }}
                _active={{ bg: "white", color: "black" }}
                pl={12}
                pr={12}
                mt={8}
                fontSize="14px"
                borderRadius="0px"
                onClick={handleLogout}
                _focus={{
                  boxShadow:
                    "0 0 0 0 rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)",
                }}
              >
                Disconnect Wallet
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Account;
