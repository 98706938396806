import React from "react";
import { Text } from "@chakra-ui/react";

const Suffix = () => (
  <svg
    width="7"
    height="11"
    viewBox="0 0 7 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ display: "inline", marginLeft: "8px" }}
  >
    <path
      d="M6.74967 5.71027L6.22997 5.18477L1.79521 0.75L0.75 1.80101L4.65946 5.71014L0.750329 9.6196L1.79553 10.6706L6.2303 6.23585L6.75 5.71021L6.74967 5.71027Z"
      fill="#F9F9F9"
    />
  </svg>
);

const Link = ({ link, children, ...rest }) => (
  <Text {...rest}>
    <a href={link} target="_blank" rel="noreferrer">
      {children}
      <Suffix />
    </a>
  </Text>
);

export default Link;
