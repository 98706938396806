export const mainnetConfig = {
  network: {
    currencySymbol: "ETH",
    chainId: "0x1",
    name: "mainnet",
    blockExplorerUrl: "https://etherscan.io/",
  },
  tokenAddress: "0x30D20208d987713f46DFD34EF128Bb16C404D10f",
  links: {
    helpCenter:
      "https://www.notion.so/staderlabs/Stader-TGE-FAQs-85f13782b7bf41ebae9c51ffb3ee9463",
    tos: "https://staderlabs.notion.site/Stader-Terms-of-Service-af2b3b2aa4c942eea76e4857faa248e4",
    wormhole: "https://portalbridge.com/#/transfer",
    terraVesting: "https://terra.staderlabs.com/sd-vesting",
    terraStaking: "https://terra.staderlabs.com/sd-staking",
    lp: "https://app.uniswap.org/#/add/v2/0x30D20208d987713f46DFD34EF128Bb16C404D10f/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48?chain=mainnet",
    learnMoreStaderToken: "https://coinlist.co/stader",
    provideLpLearnMore:
      "https://www.notion.so/staderlabs/Stader-TGE-FAQs-85f13782b7bf41ebae9c51ffb3ee9463#9e51ec7bc7f04d28baadf8d7398be1bc",
    bridgeSTaderTokenLearnMore:
      "https://www.notion.so/staderlabs/Stader-TGE-FAQs-85f13782b7bf41ebae9c51ffb3ee9463#9f5c5c8e2d934b849fb6750dc1f26168",
  },
  BASE_API_URL:
    "https://us-central1-staderverse-apis-testnet.cloudfunctions.net/",
  VESTING_URL: `https://us-central1-staderverse-apis-mainnet.cloudfunctions.net/vestedTokensApiGetUserSdVestedTokens`,
};
