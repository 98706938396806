import React from "react";
import { Box, ModalBody, Spinner, Text } from "@chakra-ui/react";

const InProgress = () => (
  <ModalBody pb="40px" pt="32px" minHeight="361px">
    <Box
      textAlign="center"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      position="absolute"
      left={0}
      right={0}
      top={0}
      bottom={0}
    >
      <Spinner size="xl" w="80px" h="80px" thickness="2px" speed="1s" />
      <Text fontSize="16px" fontWeight={600} mt="32px">
        Your transaction is in progress...
      </Text>
    </Box>
  </ModalBody>
);

export default InProgress;
