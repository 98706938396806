import React, { useState, useEffect } from "react";
import useContractData from "hooks/useContractData";
import { Switcher, Case } from "./Switcher";
import TransactionForm from "./TransactionStatus/TransactionForm";
import InProgress from "./TransactionStatus/InProgress";
import Success from "./TransactionStatus/Success";
import Fail from "./TransactionStatus/Fail";
import LongerThanUsual from "./TransactionStatus/LongerThanUsual";

const ClaimForm = ({ account, onClose, data }) => {
  const [claimStatus, setClaimStatus] = useState("initial");
  const [claimError, setClaimError] = useState(undefined);
  const { handleClaim, transactionHash, error, receipt } = useContractData();
  const [timeoutId, setTimeoutId] = useState(null);

  const { row, updateClaimData } = data;

  const handleWithdraw = async () => {
    setClaimStatus("loading");
    const claimData = {
      contractAddress: row.merkleContractAddress,
      index: row.index,
      amount: row.amount.toString(),
      account: account,
      merkleProof: row.proof,
    };
    await handleClaim(claimData);
  };

  useEffect(() => {
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setClaimError(error);
    clearTimeout(timeoutId);
    if (error) setClaimStatus("error");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    const handleReceiptUpdate = async () => {
      clearTimeout(timeoutId);
      await updateClaimData();
      setClaimStatus("success");
    };
    if (receipt) {
      handleReceiptUpdate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receipt]);

  useEffect(() => {
    if (transactionHash) {
      setTimeoutId(setTimeout(() => setClaimStatus("timeDelay"), 30000));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionHash]);

  return (
    <Switcher value={claimStatus}>
      <Case value="initial">
        <TransactionForm
          inputAmount={row.amount}
          source={row.vestingSource}
          type={row.vestingType}
          onWithdraw={handleWithdraw}
        />
      </Case>
      <Case value="loading">
        <InProgress amount={row.amount} />
      </Case>
      <Case value="success">
        <Success onClose={onClose} />
      </Case>
      <Case value="error">
        <Fail reason={claimError} onClose={onClose} />
      </Case>
      <Case value="timeDelay">
        <LongerThanUsual hash={transactionHash} onClose={onClose} />
      </Case>
    </Switcher>
  );
};

export default ClaimForm;
