import React from "react";
import { Box, Button, ModalBody, Text, Link } from "@chakra-ui/react";

import { config } from "config/config";

const TimeIcon = () => (
  <svg
    width="70"
    height="72"
    viewBox="0 0 70 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.6884 72C30.374 71.7916 29.0596 71.6245 27.7453 71.3653C20.3171 69.8632 14.0287 66.2956 8.94953 60.6906C3.90792 55.1327 0.909238 48.5964 0.184448 41.0856C-0.769421 31.2165 1.9764 22.4958 8.4219 14.9237C8.82936 14.443 9.25371 13.9717 9.69685 13.5229C10.5212 12.6874 11.3267 12.6235 12.0327 13.307C12.7387 13.9905 12.7068 14.8223 11.9031 15.6635C10.7487 16.8606 9.68846 18.145 8.73172 19.5053C5.74376 23.7353 3.8709 28.6506 3.2864 33.7964C2.53532 40.1918 3.62063 46.2662 6.58926 51.965C9.65178 57.8422 14.1564 62.3186 20.0373 65.4093C25.2949 68.177 30.9279 69.2792 36.8126 68.8549C43.6061 68.3685 49.6354 65.8843 54.8235 61.4492C60.2989 56.77 63.8834 50.9135 65.2616 43.8402C67.1937 33.9128 64.9781 24.9262 58.4831 17.1544C53.4791 11.1702 46.9785 7.68519 39.2612 6.47407C38.7673 6.39709 38.557 6.56796 38.3223 6.94537C37.8062 7.77914 37.0306 8.42047 36.1148 8.77086C35.1989 9.12126 34.1934 9.16137 33.2525 8.88503C31.2171 8.26915 29.9609 6.5473 29.9872 4.41048C30.0079 2.40511 31.3523 0.715181 33.3539 0.151873C34.3703 -0.120267 35.4502 -0.0245748 36.4029 0.422055C37.3556 0.868685 38.1198 1.63753 38.5608 2.59288C38.8124 3.13741 39.141 3.34395 39.7118 3.43221C46.2931 4.42551 52.1421 7.06742 57.1744 11.4274C62.9295 16.4259 66.5948 22.6936 68.2528 30.1387C68.7899 32.5614 69.0551 35.0365 69.0433 37.518C69.0147 45.7025 66.0911 53.613 60.7902 59.849C55.4893 66.085 48.1529 70.2443 40.0798 71.5907C39.1616 71.7484 38.2341 71.8648 37.3121 72H31.6884ZM34.4768 5.93893C35.3199 5.93893 35.8926 5.39252 35.9038 4.55695C35.9151 3.72137 35.3574 3.13929 34.5219 3.1299C34.3349 3.11623 34.1471 3.14226 33.9709 3.20628C33.7947 3.2703 33.634 3.37087 33.4994 3.50138C33.3649 3.63189 33.2594 3.7894 33.19 3.96355C33.1206 4.1377 33.0888 4.32457 33.0967 4.51188C33.0873 5.35497 33.6412 5.92954 34.4768 5.93893Z"
      fill="#F9F9F9"
    />
    <path
      d="M39.0233 37.4157C39.0279 38.3364 38.7545 39.2371 38.2389 39.9999C37.7233 40.7627 36.9895 41.3522 36.1335 41.6912C34.2821 42.4254 32.5415 42.1587 31.1407 40.7373C29.3813 38.9516 27.9486 36.8824 26.4146 34.9015C22.7931 30.2147 19.1786 25.5205 15.5709 20.8187C15.2855 20.4432 14.9738 20.0789 14.9757 19.5757C14.965 19.292 15.0358 19.0111 15.1795 18.7663C15.3232 18.5214 15.5339 18.3228 15.7868 18.1937C16.0425 18.0433 16.338 17.9743 16.6339 17.9961C16.9298 18.0179 17.212 18.1294 17.4429 18.3158C18.5157 19.1082 19.5798 19.9124 20.635 20.7286C26.1179 24.9684 31.6402 29.1538 37.0517 33.4875C38.3192 34.5146 39.0308 35.7727 39.0233 37.4157ZM25.3349 28.2319L25.2222 28.3445C26.653 30.1922 28.0845 32.0405 29.5165 33.8894C30.4798 35.1268 31.4543 36.3567 32.4288 37.5847C32.646 37.861 32.8793 38.1243 33.1273 38.3733C33.8577 39.1075 34.8435 39.1394 35.4913 38.4616C36.1391 37.7837 36.0716 36.7716 35.3148 36.09C34.6182 35.4685 33.8671 34.8902 33.1367 34.3269C30.538 32.289 27.9374 30.2573 25.3349 28.2319Z"
      fill="#F9F9F9"
    />
  </svg>
);

const LongerThanUsual = ({ hash, onClose }) => {
  return (
    <ModalBody pb="40px" pt="32px" minHeight="361px">
      <Box
        textAlign="center"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        position="absolute"
        left={0}
        right={0}
        top={0}
        bottom={0}
      >
        <TimeIcon />
        <Text fontSize="18px" fontWeight={600} mt="32px">
          Transaction is taking longer than usual!
        </Text>
        <Text fontSize="16px" fontWeight={400} mt="30px">
          Please click on the link below to view transaction.
        </Text>
        <Link
          href={`${config.network.blockExplorerUrl}tx/${hash}`}
          fontSize="16px"
          fontWeight={600}
          mt="5px"
          _focus={{
            boxShadow:
              "0 0 0 0 rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)",
          }}
          isExternal
        >
          View Your Transaction here
        </Link>
        <Button
          mt={"36px"}
          onClick={onClose}
          variant="outline"
          _hover={{ bg: "white", color: "black" }}
          _active={{ bg: "white", color: "black" }}
          pl={12}
          pr={12}
          fontSize="14px"
          borderRadius="0px"
          _focus={{
            boxShadow:
              "0 0 0 0 rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)",
          }}
        >
          Close
        </Button>
      </Box>
    </ModalBody>
  );
};

export default LongerThanUsual;
