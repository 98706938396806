import {
  Button,
  Flex,
  Image,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { Contract } from "ethers";
import React, { useEffect, useState } from "react";
import { useProvider } from "wagmi";
import styles from "./VestingTable.module.scss";

import merkleDistributor from "contracts/MerkleDistributor.json";

import checkIcon from "assets/svg/tick.svg";

const THeaderCell = (props) => (
  <Th
    {...props}
    fontSize="large"
    color={"white"}
    textTransform="capitalize"
    pt="1em"
    pb="2em"
    ps={0}
  />
);

const TCell = (props) => <Td {...props} fontSize="md" ps={0} />;

const VestingTableRow = ({ row, onClaimClick }) => {
  const [isClaimed, setClaimed] = useState();

  const updateClaimData = async () => {
    await fetchClaim();
  };

  const provider = useProvider();

  const fetchClaim = async () => {
    const contract = new Contract(
      row.merkleContractAddress,
      merkleDistributor.abi,
      provider,
    );

    const response = await contract.isClaimed(row.index);

    setClaimed(response);
  };

  useEffect(() => {
    if (row) fetchClaim();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row]);

  const vestingDate = new Date(row.vestingDate);
  // console.log(mydate.toDateString());
  return (
    <Tr borderColor={"#000000"} color={row.claimDate ? "#8C8C8C" : undefined}>
      <TCell>{row.amount / 10 ** 18} SD</TCell>
      {row.vestingSource && (
        <TCell>
          <Text fontSize="small" fontWeight="bold" color="#8C8C8C">
            {row.vestingType}
          </Text>
          <Text fontWeight="bold">{row.vestingSource}</Text>
        </TCell>
      )}
      {!row.vestingType && <TCell>{row.vestingType}</TCell>}
      <TCell>{format(vestingDate, "dd MMM'' yy")}</TCell>
      {isClaimed && (
        <TCell pr={0} width="130px">
          <Flex gap="0.5rem" align="center">
            <Image src={checkIcon} boxSize="24px" />
            <Text fontSize="md" textAlign={"center"}>
              Claimed
            </Text>
          </Flex>
        </TCell>
      )}
      {isClaimed === false && (
        <TCell textAlign={row.claimDate ? "start" : "end"} pr={0} width="130px">
          <Button
            onClick={() => onClaimClick(row, updateClaimData)}
            variant="outline"
            _hover={{ bg: "white", color: "black" }}
            _active={{ bg: "white", color: "black" }}
            pl={12}
            pr={12}
            fontSize="14px"
            borderRadius="0px"
            _focus={{
              boxShadow:
                "0 0 0 0 rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)",
            }}
          >
            Claim
          </Button>
        </TCell>
      )}
    </Tr>
  );
};

/**
 * onClaimClick: MouseEventHandler
 * data: table data
 */
const VestingTable = ({ onClaimClick, data, isDataLoading }) => {
  if (data?.length === 0) {
    return (
      <Flex p={16} justifyContent={"center"}>
        <Text
          fontSize={24}
          textAlign={"center"}
          fontFamily="Montserrat"
          letterSpacing={"tighter"}
        >
          {isDataLoading
            ? `Fetching data...`
            : `There are no SD tokens available for this account`}
        </Text>
      </Flex>
    );
  }

  return (
    <div className={styles.vestingTable__container}>
      <Table
        size="md"
        color={"white"}
        variant="unstyled"
        className={styles.vestingTable}
      >
        <Thead color={"white"} height={"20px"}>
          <Tr color={"white"} height="20px" fontSize="md">
            <THeaderCell>Amount</THeaderCell>
            <THeaderCell>Type</THeaderCell>
            <THeaderCell>Vesting Date</THeaderCell>
            <Th color={"white"}></Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((t, i) => (
            <VestingTableRow key={i} row={t} onClaimClick={onClaimClick} />
          ))}
        </Tbody>
      </Table>
    </div>
  );
};

export default VestingTable;
