import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import { useConnect } from "wagmi";
import { walletIconMapping, walletLabelMapping } from "constants/wallets";

const PreScreen = () => {
  const toast = useToast();

  const { connect, connectors } = useConnect({
    onError(error) {
      let errorMsg = "";

      if (error.message.toString().startsWith("invalid address")) {
        errorMsg =
          "Looks like you are not connected to ETH chain. Please switch the network in the Wallet.";
      }

      toast({
        description: errorMsg || error.message,
        status: "error",
        duration: 5000,
        position: "bottom",
        isClosable: true,
      });
    },
    onSuccess() {
      onClose();
    },
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex flexDir={"column"} alignItems="center">
      <Flex
        maxWidth={"600px"}
        alignItems="center"
        justifyContent="center"
        bg="#111111"
        border="1px solid #3E3E3E"
        boxSizing="border-box"
        boxShadow="3px 3px 12px #010101"
        m={"4px"}
        p={"4px"}
        flexDirection="column"
      >
        <Text
          p={4}
          fontFamily={"Montserrat"}
          fontSize={"32px"}
          textAlign={"center"}
          fontWeight={"bold"}
          letterSpacing={"tighter"}
        >
          Please connect Your wallet to view Stader token vesting
        </Text>

        <Button
          m={8}
          p={6}
          variant="outline"
          onClick={onOpen}
          color="white"
          _hover={{ bg: "white", color: "black" }}
          _active={{ bg: "white", color: "black" }}
          borderColor="#8c8c8c"
          borderRadius="0px"
          _focus={{
            boxShadow:
              "0 0 0 0 rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)",
          }}
        >
          Connect Wallet
        </Button>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent bg={"#191919"} color={"#ffffff"}>
            <ModalHeader>Connect Wallet</ModalHeader>
            <ModalCloseButton
              _focus={{
                boxShadow: "0 0 0 0 ",
              }}
            />
            <ModalBody py={8}>
              <SimpleGrid columns={2} gap="0.5rem">
                {connectors.map((connector) => (
                  <Flex
                    gap="1rem"
                    flexDir="column"
                    align="center"
                    py="0.5rem"
                    mx="1rem"
                    key={connector.id}
                    cursor="pointer"
                    border="1px solid transparent"
                    onClick={async () => {
                      try {
                        connect({ connector });
                      } catch (e) {
                        console.error(e);
                      }
                    }}
                    _hover={{
                      borderColor: "white",
                    }}
                    transition="all 0.2s ease-in-out"
                  >
                    <Flex overflow="hidden" borderRadius="50%">
                      <img
                        src={walletIconMapping[connector.id.toLowerCase()]}
                        alt={walletLabelMapping[connector.id.toLowerCase()]}
                        height="32px"
                        width="32px"
                      />
                    </Flex>
                    <Text fontSize={14}>
                      {walletLabelMapping[connector.id.toLowerCase()]}
                    </Text>
                  </Flex>
                ))}
              </SimpleGrid>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Flex>
    </Flex>
  );
};

export default PreScreen;
