import {
  Button,
  Flex,
  Heading,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import ClaimForm from "components/ClaimForm";
import Footer from "components/Footer";
import Header from "components/Header";
import ImportantLiinks from "components/ImportantLiinks";

import PreScreen from "components/PreScreen";
import VestingTable from "components/Vesting/VestingTable";
import { useEffect, useState } from "react";
import { config } from "config/config";
import styles from "./App.module.scss";
import { useAccount, useBalance, useNetwork } from "wagmi";
import { toFixedWithoutRounding } from "utils/common";

const axios = require("axios").default;

const App = () => {
  const [selectedOption, setSelectedOption] = useState(undefined);
  const [tableData, setTableData] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const isDesktop = useMediaQuery("(min-width: 1281px)");

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { address, isConnected, connector } = useAccount();

  const { chain } = useNetwork();

  const balance = useBalance({
    address: address,
    token: config.tokenAddress,
    watch: true,
    enabled: !!address,
  });

  const displayBal = +balance?.data?.formatted
    ? +toFixedWithoutRounding(balance?.data?.formatted, 4)
    : 0;

  useEffect(() => {
    if (!isOpen) {
      setSelectedOption(undefined);
    }
  }, [isOpen]);

  const getData = async () => {
    const walletAddress =
      address || "0x0000000000000000000000000000000000000000";
    const { data } = await axios.post(config.VESTING_URL, {
      address: walletAddress.toLowerCase(),
    });
    const { userSdVestedTokens } = data;
    setTableData(userSdVestedTokens);
    setIsDataLoading(false);
  };

  useEffect(() => {
    if (isConnected) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  const handleClick = (row, updateClaimData) => {
    setSelectedOption({ row, updateClaimData });
    onOpen();
  };

  return (
    <>
      <Header balance={displayBal} />
      <Flex
        // bg={useColorModeValue("purple", "gray.600")}
        flex={1}
        flexDirection="column"
        className={styles.layout}
        color="white"
      >
        <Flex flexDir={"column"}>
          {!isConnected || !address ? (
            <PreScreen />
          ) : chain.id === +config.network.chainId ? (
            <>
              <Heading p={4} ps={0} fontFamily={"Montserrat"}>
                SD token vesting: Ethereum ERC20
              </Heading>
              <VestingTable
                data={tableData}
                onClaimClick={handleClick}
                isDataLoading={isDataLoading}
              />
              <ImportantLiinks />
              <Footer />
            </>
          ) : (
            <>
              <Flex
                flexDirection={"column"}
                padding={"10px"}
                alignItems="center"
              >
                <Text
                  p={4}
                  fontFamily={"Montserrat"}
                  fontSize={"24px"}
                  fontWeight={"18px"}
                  textAlign="center"
                  padding={"30px"}
                >
                  {`We do not support this network. Please switch to ${config.network.name} network.`}
                </Text>
                <Button
                  variant="outline"
                  _hover={{ bg: "white", color: "black" }}
                  _active={{ bg: "white", color: "black" }}
                  fontSize="lg"
                  height="48px"
                  py="15px"
                  width={"200px"}
                  borderRadius="0px"
                  _focus={{
                    boxShadow:
                      "0 0 0 0 rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)",
                  }}
                  onClick={async () => {
                    await connector.switchChain?.(+config.network.chainId);
                  }}
                >
                  Switch network
                </Button>
              </Flex>
            </>
          )}
        </Flex>

        <Modal
          isOpen={isOpen}
          onClose={onClose}
          isCentered
          size={isDesktop ? "2xl" : "md"}
          closeOnOverlayClick={false}
          closeOnEsc={false}
        >
          <ModalOverlay />
          <ModalContent bg="#1e1e1e" color="white" borderRadius={0}>
            {selectedOption && (
              <ClaimForm
                data={selectedOption}
                amount={selectedOption.amount / 10 ** 18}
                account={address}
                onClose={onClose}
              />
            )}
          </ModalContent>
        </Modal>
      </Flex>
    </>
  );
};

export default App;
