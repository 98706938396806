import { Flex, Link, Text } from "@chakra-ui/react";
import { config } from "config/config";
import React from "react";

// const Divider = () => (
//   <hr
//     style={{
//       backgroundColor: "#3e3e3e",
//       color: "#3e3e3e",
//       border: "none",
//       height: "1px",
//     }}
//   />
// );

const HelpIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="11.5" stroke="#F9F9F9" />
    <path
      d="M9 9.22074C9 8.91194 9.04447 8.20171 10.0357 7.38463C10.6243 6.89951 11.3311 6.75901 11.9644 6.75036C12.5428 6.74326 13.0598 6.84115 13.3689 6.99184C13.8972 7.25061 14.9289 7.88056 14.9289 9.22074C14.9289 10.631 14.0278 11.2702 13.0036 11.9746C11.9793 12.679 11.7174 13.1227 11.7174 13.9144"
      stroke="#F9F9F9"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M12.4737 15.5203H10.9606C10.9009 15.5203 10.8525 15.5687 10.8525 15.6283V17.1414C10.8525 17.2011 10.9009 17.2495 10.9606 17.2495H12.4737C12.5334 17.2495 12.5818 17.2011 12.5818 17.1414V15.6283C12.5818 15.5687 12.5334 15.5203 12.4737 15.5203Z"
      fill="#F9F9F9"
    />
  </svg>
);

const Footer = () => {
  return (
    <Flex
      direction={"row"}
      mt="32px"
      alignItems={"flex-end"}
      cursor="pointer"
      justifyContent={"flex-end"}
    >
      <Link
        href={config.links.helpCenter}
        isExternal
        _focus={{
          boxShadow: "0 0 0 0 ",
        }}
      >
        <Flex direction="row">
          <HelpIcon />
          <Text
            color="#ffffff"
            fontSize="md"
            fontWeight={"semibold"}
            ml={"8px"}
          >
            Help Center
          </Text>
        </Flex>
      </Link>
      <Text color="#ffffff" fontSize="md" fontWeight={"semibold"} ml={"8px"}>
        |
      </Text>
      <Link
        href={config.links.tos}
        isExternal
        _focus={{
          boxShadow: "0 0 0 0 ",
        }}
      >
        <Flex direction="row">
          <Text
            color="#ffffff"
            fontSize="md"
            fontWeight={"semibold"}
            ml={"8px"}
          >
            Terms of Service
          </Text>
        </Flex>
      </Link>
    </Flex>
  );
};

export default Footer;
