// Images
import metamaskIcon from "assets/svg/metamaskwallet.svg";
import walletConnectIcon from "assets/svg/walletconnect.svg";
import coinbaseWalletIcon from "assets/svg/coinbasewallet.svg";
import coin98WalletIcon from "assets/svg/coin98wallet.svg";
import safePalWalletIcon from "assets/svg/safepalwallet.svg";
import mathWalletIcon from "assets/svg/mathwallet.svg";
import trustWalletIcon from "assets/svg/trustwallet.svg";
import bitKeepIcon from "assets/images/bitkeep.jpeg";
import okexIcon from "assets/images/okex.png";
import kuIcon from "assets/svg/kuwallet.svg";

export const WALLETS = {
  INJECTED: "injected",
  METAMASK: "metamask",
  COINBASE: "coinbasewallet",
  WALLET_CONNECT: "walletconnect",
  COIN98: "coin98",
  SAFEPAL: "safepal",
  MATH_WALLET: "mathwallet",
  TRUST_WALLET: "trustwallet",
  BITKEEP: "bitkeep",
  OKEX: "okex",
  KUCOIN: "kucoin",
};

export const walletIconMapping = {
  [WALLETS.INJECTED]: metamaskIcon,
  [WALLETS.METAMASK]: metamaskIcon,
  [WALLETS.COINBASE]: coinbaseWalletIcon,
  [WALLETS.WALLET_CONNECT]: walletConnectIcon,
  [WALLETS.COIN98]: coin98WalletIcon,
  [WALLETS.SAFEPAL]: safePalWalletIcon,
  [WALLETS.MATH_WALLET]: mathWalletIcon,
  [WALLETS.TRUST_WALLET]: trustWalletIcon,
  [WALLETS.BITKEEP]: bitKeepIcon,
  [WALLETS.OKEX]: okexIcon,
  [WALLETS.KUCOIN]: kuIcon,
};

export const walletLabelMapping = {
  [WALLETS.INJECTED]: "Metamask",
  [WALLETS.METAMASK]: "Metamask",
  [WALLETS.COINBASE]: "Coinbase Wallet",
  [WALLETS.WALLET_CONNECT]: "Wallet Connect",
  [WALLETS.COIN98]: "Coin98 Wallet",
  [WALLETS.SAFEPAL]: "SafePal Wallet",
  [WALLETS.MATH_WALLET]: "Math Wallet",
  [WALLETS.TRUST_WALLET]: "Trust Wallet",
  [WALLETS.BITKEEP]: "BitKeep Wallet",
  [WALLETS.OKEX]: "OKX Wallet",
  [WALLETS.KUCOIN]: "KuCoin Wallet",
};
