import { Heading, Flex, Text } from "@chakra-ui/react";
import { config } from "config/config";
import React from "react";
import Link from "./Link";

const Divider = () => (
  <hr
    style={{
      backgroundColor: "#3e3e3e",
      color: "#3e3e3e",
      border: "none",
      height: "1px",
    }}
  />
);

const LearnMoreLink = ({ link, linkMore, children }) => (
  <>
    <Link link={link} fontSize="18px" fontWeight="semibold" mt="24px">
      {children}
    </Link>
    <Text color="#8C8C8C" fontSize="md" fontWeight={"semibold"} mt={"4px"}>
      <a href={linkMore} target="_blank" rel="noreferrer">
        Learn more
      </a>
    </Text>
  </>
);

const ImportantLiinks = () => {
  return (
    <Flex direction={"column"} mt="32px">
      <Link
        link={config.links.learnMoreStaderToken}
        fontSize="large"
        fontWeight="semibold"
        mb="32px"
      >
        *Learn more about Stader token vesting here
      </Link>
      <Divider />
      <Heading
        p={4}
        ps={0}
        fontFamily={"Montserrat"}
        mt={"32px"}
        letterSpacing={"tight"}
      >
        Important links
      </Heading>
      <LearnMoreLink
        link={config.links.lp}
        linkMore={config.links.provideLpLearnMore}
      >
        Provide LP on Stader tokens
      </LearnMoreLink>
    </Flex>
  );
};

export default ImportantLiinks;
