import React from "react";
import { Box, Button, ModalBody, Text } from "@chakra-ui/react";

const SuccessIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="40" cy="40" r="38.5" stroke="#F9F9F9" strokeWidth="3" />
    <path
      d="M53.7306 28.5634L34.8499 47.3972L27.241 39.8355C26.4894 39.0839 25.3152 39.0839 24.5637 39.8355C23.8121 40.587 23.8121 41.7612 24.5637 42.5128L33.4874 51.4365C33.8632 51.8123 34.3326 52 34.8025 52C35.2723 52 35.7417 51.8123 36.1176 51.4365L56.3134 31.2407C57.0649 30.4891 57.0649 29.3149 56.3134 28.5634C55.6562 27.8122 54.4351 27.8122 53.7308 28.5637L53.7306 28.5634Z"
      fill="#F9F9F9"
    />
  </svg>
);

const Success = ({ amount, onClose }) => (
  <ModalBody pb="40px" pt="32px" minHeight="361px">
    <Box
      textAlign="center"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      position="absolute"
      left={0}
      right={0}
      top={0}
      bottom={0}
    >
      <SuccessIcon />
      <Text fontSize="16px" fontWeight={600} mt="32px">
        You have successfully claimed {amount} SD tokens
      </Text>
      <Button
        mt={"30px"}
        onClick={onClose}
        variant="outline"
        _hover={{ bg: "white", color: "black" }}
        _active={{ bg: "white", color: "black" }}
        pl={12}
        pr={12}
        fontSize="14px"
        borderRadius="0px"
        _focus={{
          boxShadow:
            "0 0 0 0 rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)",
        }}
      >
        Close
      </Button>
    </Box>
  </ModalBody>
);

export default Success;
