// import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import { ChakraProvider } from "@chakra-ui/provider";
import { extendTheme } from "@chakra-ui/react";
import { WagmiConfig, configureChains, createClient, mainnet } from "wagmi";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import { InjectedConnector } from "wagmi/connectors/injected";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import {
  BitKeepConnector,
  Coins98WalletConnector,
  KuCoinWalletConnector,
  MathWalletConnector,
  OkexConnector,
  SafePalWalletConnector,
  TrustWalletConnector,
} from "config/connectors/connectors";

const theme = extendTheme({
  colors: {
    brand: {
      100: "#f7fafc",
      // ...
      900: "#1a202c",
    },
  },
  styles: {
    global: {
      body: {
        minHeight: "100vh",
        bg: "#0e0e0e",
        // TODO: gradient
        // bg: `radial-gradient(
        //   circle at 80% bottom,
        //   rgba(101,101,101,1) 0%, rgba(63,63,63,1) 6%, rgba(14,14,14,1) 17%, rgba(14,14,14,1) 50%
        // )`,
        fontFamily: "Montserrat",
        transition: "none",
      },
    },
  },
});

const { chains, provider } = configureChains(
  [mainnet],
  [
    alchemyProvider({ apiKey: "K8WJjnAXsC4mf7xcSRGKno9bGwxpjXGW" }),
    publicProvider(),
  ],
);

const client = createClient({
  autoConnect: true,
  provider,
  connectors: [
    new InjectedConnector({ chains }),
    new WalletConnectConnector({
      chains,
      options: {
        qrcode: true,
      },
    }),
    new CoinbaseWalletConnector({
      options: {
        appName: "Stader Labs",
      },
    }),
    new Coins98WalletConnector({
      chains,
      options: {
        shimDisconnect: true,
      },
    }),
    new SafePalWalletConnector({
      chains,
      options: {
        shimDisconnect: true,
      },
    }),
    new MathWalletConnector({
      chains,
      options: {
        shimDisconnect: true,
      },
    }),
    new TrustWalletConnector({
      chains,
      options: {
        shimDisconnect: true,
      },
    }),
    new BitKeepConnector({
      chains,
      options: {
        shimDisconnect: true,
      },
    }),
    new OkexConnector({
      chains,
      options: {
        shimDisconnect: true,
      },
    }),
    new KuCoinWalletConnector({
      chains,
      options: {
        shimDisconnect: true,
      },
    }),
  ],
});

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <WagmiConfig client={client}>
      <App />
    </WagmiConfig>
  </ChakraProvider>,

  document.getElementById("root"),
);
